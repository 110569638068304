import Login from '../pages/Login/index'
import Home from '../pages/Home/index'
import CasePage from '../pages/Case/index'
import AdminList from '../pages/List'
import CaseAdmin from '../pages/CaseAdmin'
import NewsAdmin from '../pages/HomeAdmin'
const navList = [
  {
    title: "登录",
    route: '/login',
    component: Login
  },
  {
    title: "新闻",
    route: '/home',
    component: Home
  },
  {
    title: "案例",
    route: '/case',
    component: CasePage
  },
  {
    title: "管理",
    route: '/admin',
    component: AdminList
  },
  {
    title: "新闻编辑",
    route: '/caseAdmin/:id',
    component: CaseAdmin
  },
  {
    title: "案例编辑",
    route: '/newsAdmin/:id',
    component: NewsAdmin
  }

]

export default navList