import React from 'react'
import LoginForm from '../../components/Login/Login'
import './styles/index.less'

export default class Login extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <div className='header'>
                    <h1 className='head-title'>ECO-ADMIN</h1>
                    </div>
                    <img className='fish_login' src={require('../../assets/images/fish.png')} alt='小鱼哦' />
                    <div className="content">
                    </div>
                    <div className='login'>
                        <LoginForm />
                    </div>
                    <div className='foot' >
                        <div>
                            <a href='https://www.wh-eco.com/contact' target='_blank'>联系我们</a>
                            <span> | </span>
                            <a href='https://www.wh-eco.com/about' target='_blank'>关于我们</a>
                        </div>
                        <span>©2018 易科捷（武汉）生态科技有限公司</span>
                    </div>
                </div>
            </div>
        )
    }
}
