// const ctx = 'http://192.168.0.213:8898/'
const ctx = '/api/'

const contentHeight = document.documentElement.clientHeight - 242;
const token = localStorage.getItem('token');
export {
  ctx,
  contentHeight,
  token
}
