import React from 'react'
import E from 'wangeditor'
import { Input, Button, Modal, message } from 'antd'
import './styles/index.less'
import {ctx} from '../../common/global'
import axios from 'axios'
let imgurl = null
const token = localStorage.getItem('token')
export default class Home extends React.Component {
  constructor () {
    super()
    this.state = {
      fileList: [],
      titleValue:'', 
      contentValus:''
    }
  }

  componentDidMount () {
    const editor = new E(this.editor)
    // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
    // editor.customConfig.uploadImgServer = ctx+'/images/upload'
    editor.customConfig.showLinkImg = false
    editor.customConfig.pasteFilterStyle = false
    editor.customConfig.pasteTextHandle = function (content) {
      // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      if (content == '' && !content) return ''
      let str = content;
      //处理的标签里的多余代码
      str = str.replace(/<xml>[\s\S]*?<\/xml>/ig, '');
      str=str.replace('/(\\n|\\r| class=(")?Mso[a-zA-Z]+(")?)/g','');
      let reg=new RegExp('<!--(.*?)-->','g')
      str=str.replace(reg,'');
      //str = str.replace(/<style>[\s\S]*?<\/style>/ig, '')
      //str = str.replace(/<\/?[^>]*>/g, '')
     // str = str.replace(/[ | ]*\n/g, '\n')
     // str = str.replace(/&nbsp;/ig, '')
      console.log('富文本的content',JSON.parse(JSON.stringify(content)))
      console.log('****str修改后的content str', str)
      return str
    }
    editor.customConfig.customUploadImg = function (files, insert) {
      var fd = new FormData();
      fd.append("imageFile", files[0]);
      axios.post(`${ctx}images/upload`,fd).then(rst=>{
        insert(rst.data.data);
        imgurl = rst.data.data
      }).catch(err=>{
        message.error(`error：${err}`)
      })
  }
    editor.customConfig.uploadFileName = 'image'
    editor.customConfig.zIndex = 100
    editor.customConfig.onchange = html => {
      this.setState({contentValus:html})
    }
    editor.create()
  }
  titleChange = (e,type) => {
    this.setState({[type]: e.target.value })
  }
  buttonSubmit = () => {
    const {titleValue, contentValus} = this.state    
    if(titleValue==='' || contentValus === '' ){
      message.error('请确保所有参数均已填写')
    }else if(imgurl === null){
      message.error('请至少上传一张图片')
    }
    else{
      let data = {
        title:titleValue,
        imgURL:imgurl,
        content:contentValus
      }
      debugger
      axios({
        method:'POST',
        url:`${ctx}addCase`,
        data:data,
        headers:{
          Authorization:token
        }
      })
      .then(rst=>{

        const dt = rst.data
        
        console.log(rst)
        if(dt.status){
          Modal.success({
            title: '通知',
            content: dt.msg,
            onOk(){window.location.reload()}
          });
        }else{
          Modal.error({
            title: '通知',
            content: dt.msg
          });
          localStorage.clear()
          window.location.reload()
        }
        
        
      }).catch(err=>{
        message.error(`error：${err}`)
      })
    } 
  }
  render () {
    const {titleValue, fileList} = this.state
    const props = {
      fileList,
      action:'',
      onRemove: (file) => {
        this.setState(({ fileList }) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          }
        })
      },
      beforeUpload: (file) => {
        this.setState(({ fileList }) => ({
          fileList: [...fileList, file],
        }))
        console.log("图片",this.state.fileList)
        return false
      },
    }
    return (
      <div className='news-publish'>
        <div className="tips">请在内容中至少添加一张图片！</div>
        <div className='row row-title'>
            <span className="title">标题:</span>
            <Input onChange={(e) => {this.titleChange(e,'titleValue')}} value={titleValue} style={{width:500}} />
        </div>
        <div className='row'>
          <span className="title" >内容:</span>
          <div className='label-content' ref={newref => { this.editor = newref }} />
        </div>
        <div className='row'>
          <div style={{marginTop: 20}}>
            <Button type='primary' className='row-submit' onClick={this.buttonSubmit}>确认提交</Button>
          </div>
        </div>
      </div>
    )
  }
}
