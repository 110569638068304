import * as api from '../services/list';
import { message } from 'antd';
export default {
  namespace: 'list',
  state: {
    caseList:[],
    newsList:[]
  },
  effects: {
    *getCaseList({ payload }, { call, put }) {
      const data = yield call(api.getCaseList, payload)
      let items = []
      let totalPage = 0
      if(data.data.status){
        items = data.data.data
        totalPage = data.data.totalPage
      }else{
        items = []
        totalPage = 0
        message.error("暂无数据！")
      }
      yield put({
        type: "saveData",
        payload: {
          items,
          totalPage
        }
      });
    },
    *getNews({ payload }, { call, put }) {
      const data = yield call(api.getNews, payload)
      let items = []
      let totalPage = 0
      if(data.data.status){
        items = data.data.data
        totalPage = data.data.totalPage
      }else{
        items = []
        totalPage = 0
        message.error("暂无数据！")
      }
      yield put({
        type: "saveNewsData",
        payload: {
          items,
          totalPage
        }
      });
    },
    *delNews({ payload }, { call, put }) {
      const data = yield call(api.delNews, payload)
      debugger
      let items = []
      let totalPage = 0
      if(data.data.status){
        items = data.data.data
        totalPage = data.data.totalPage
      }else{
        items = []
        totalPage = 0
        message.error("暂无数据！")
      }
      yield put({
        type: "saveNewsData",
        payload: {
          items,
          totalPage
        }
      });
    },
  },
  reducers: {
    'saveData'(state, { payload }) {
      return Object.assign({}, state, {
        "caseList": payload.items,
        "newsPage":payload.totalPage
      })
    },
    'saveNewsData'(state, { payload }) {
      return Object.assign({}, state, {
        "newsList": payload.items,
        "casePage":payload.totalPage
      })
    },
  },
} 