import * as api from '../services/list';
import { message } from 'antd';
export default {
  namespace: 'detiles',
  state: {
  },
  effects: {
    *getNewsContent({ payload }, { call, put }) {
      const data = yield call(api.getNewsContent, payload)
      yield put({
        type: "saveData",
        payload: {
          data
        }
      });
    },
    *getCaseContent({ payload }, { call, put }) {
      const data = yield call(api.getCaseContent, payload)
      yield put({
        type: "saveCaseData",
        payload: {
          data
        }
      });
    }
  },
  reducers: {
    'saveData'(state, { payload }) {
      return Object.assign({}, state, {
        "newsContent": payload.data.data.data
      })
    },
    'saveCaseData'(state, { payload }) {
      return Object.assign({}, state, {
        "casedata": payload.data.data.data
      })
    }
  },
} 