import React from 'react';
// import ReactDOM from 'react-dom';
import dva from 'dva';
import App from './App';
import './index.css';
import modelsArr from './models/index';

const app = dva();
modelsArr.map((v, i) => {
  app.model(v);
});
app.router(() => <App />);
app.start('#root');
