import React, { Component } from 'react';
import { connect } from 'dva';
import { Button } from 'antd';
import './styles/index.less';

class Logout extends Component {
  state = { visible: false };

  // async logout(type) {
  //   const result = await api.logout({
  //     username,
  //     token,
  //     projectEnglishName
  //   });
  //   const { status } = result.data;
  //   debugger
  //   if (status && type == 'station') {
  //     localStorage.removeItem('stationId');
  //     window.location.href = '/station';
  //   } else if (status && type == 'system') {
  //     localStorage.clear();
  //     window.location.href = '/login';
  //   } else {
  //     message.error('退出失败');
  //   }
  // }
  handLogout(){
    localStorage.clear()
    window.location.href = '/login';
  }
  render() {
    return (
      <div className="headerinfo">
        <div className="left">
          <span className="big"> ECO-ADMIN </span>{' '}
        </div>
        <div className="right">
            <Button type="primary" icon="logout" onClick={this.handLogout.bind(this)}>退出</Button>
        </div>
      </div>
    );
  }
}

export default connect(({ login }) => ({
  login
}))(Logout);
