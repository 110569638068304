import React from 'react'
import { Table, Divider, Button, Pagination, message, Modal} from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'dva'
import './styles/index.less'
import {ctx} from '../../common/global'
import axios from 'axios'
let imgurl = null
const token = localStorage.getItem('token')
class AdminList extends React.Component {
  constructor () {
    super()
    this.state = {
      current:1,
      page:1
    }
  }
  componentDidMount () {
    this.getNews()
    this.getCaseList()
  }  
  getNews(){
      this.props.dispatch({
        type: 'list/getNews',
        payload:{
          type:'all',
          page:this.state.current
        }
      })
  }
  getCaseList(){
    this.props.dispatch({
      type: 'list/getCaseList',
      payload:{
        page:this.state.page
      }
    })
  }
  casePage(v){
    this.setState({page:v},
    () => {
      this.getCaseList()
    }  
    )
  }
  newsPage(v){
    this.setState({current:v},
      () => {
        this.getNews()
      }
      )
  }
  edit = v => {
    
  }
  delNews(v){
    let data = {
      id:v
    }
    axios({
      method:'POST',
      url:`${ctx}delNews`,
      params:data,
      headers:{
        Authorization:token
      }
    })
    .then(rst=>{
      const dt = rst.data
      console.log(rst)
      if(dt.status){
        Modal.success({
          title: '通知',
          content: dt.msg,
          onOk(){window.location.reload()}
        });
      }else{
        Modal.error({
          title: '通知',
          content: dt.msg
        });
        localStorage.clear()
        window.location.reload()
      }
      
      
    }).catch(err=>{
      message.error(`error：${err}`)
    })
  }
  delCase(v){
    let data = {
      id:v
    }
    axios({
      method:'POST',
      url:`${ctx}delCase`,
      params:data,
      headers:{
        Authorization:token
      }
    })
    .then(rst=>{
      const dt = rst.data
      console.log(rst)
      if(dt.status){
        Modal.success({
          title: '通知',
          content: dt.msg,
          onOk(){window.location.reload()}
        });
      }else{
        Modal.error({
          title: '通知',
          content: dt.msg
        });
        localStorage.clear()
        window.location.reload()
      }
      
      
    }).catch(err=>{
      message.error(`error：${err}`)
    })
  }
  render () {
    const { caseList, newsList, newsPage, casePage } = this.props.list
    let newsData = []
    let caseData = []
    if(caseList.length > 0){
      caseList.map((v,i) =>{
        caseData.push({
          key:v.id,
          name:v.title
        })
      })
    }
    if(newsList.length > 0){
      newsList.map((v,i) =>{
        newsData.push({
          key:v.key,
          name:v.title
        })
      })
    }
    const columns = [
      {
        title: '标题',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '操作',
        width:300, 
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={'/newsAdmin/' + record.key} ><Button onClick={() => this.edit(record.key)}>修改</Button></Link>
            <Divider type="vertical" />
            <Button onClick={() => this.delNews(record.key)}>删除</Button>
          </span>
        ),
      },
    ]
    const caseColumns = [
      {
        title: '标题',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '操作',
        width:300, 
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={'/caseAdmin/' + record.key} ><Button onClick={() => this.edit(record.key)}>修改</Button></Link>
            <Divider type="vertical" />
           <Button onClick={() => this.delCase(record.key)}>删除</Button>
          </span>
        ),
      },
    ]
    const data = [
      {
        key: '1',
        name: 'John Brown'
      }
    ]
    return (
      <div className='adminList'>
      <h1>新闻</h1>
      <Table columns={columns} dataSource={newsData} pagination={false} />
      <Pagination  total={newsPage} pageSize={5}  onChange={this.newsPage.bind(this)}  />
      <h1>案例</h1>
      <Table columns={caseColumns} dataSource={caseData} pagination={false} />
      <Pagination  total={casePage} pageSize={8}  onChange={this.casePage.bind(this)}  />
      </div>
    )
  }
}
export default connect(({ list }) => ({
  list
}))(AdminList); 