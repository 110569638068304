import request from '../utils/request';
import { ctx } from '../common/global';

export function getNews(payload) {
  let page = payload.page
  let type = 'all'  
  return request(`${ctx}getNews/${page}?type=${type}`)
}
export function getCaseList(payload) {
  let page = payload.page
  return request(`${ctx}getCaseList/${page}`)
}
export function getNewsContent(payload) {
  let key = payload.key
  return request(`${ctx}getNewsContent?key=${key}`)
}
export function getCaseContent(payload) {
  let id = payload.id
  return request(`${ctx}getCaseContent?id=${id}`)
}
export function delNews(payload) {
  let id = payload.id
  debugger
  return request(`${ctx}delNews`,{method:'DELETE',body:JSON.stringify({id:id})})
}