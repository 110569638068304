import React from 'react';
import { connect } from 'dva';
import { Form, Icon, Input, Button, message } from 'antd';
import './styles/index.less';
import axios from 'axios'
import {ctx} from '../../common/global'
const FormItem = Form.Item;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginBtnText: '登录',
      loginBtnDisable: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
      } else {
        const { username, password } = values;
        // window.location.href = '/home';
        let data = {
          password: password,
          username: username
        }
        // localStorage.setItem(`token`, '111');
            // window.location.href = '/home';
        axios.post(`${ctx}user/login`,data).then(rst=>{
          if(rst.data.status){
            localStorage.setItem(`token`, rst.data.token);
            window.location.href = '/home';
          }else{
            message.error("用户名或密码输入错误！")
          }
        }).catch(err=>{
          message.error(`error：${err}`)
        })
        
      }
    });
  }

  render() {
    const { loginBtnText, loginBtnDisable } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <div className="login-title"> 用户登录 </div>
        <FormItem>
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: '用户名'
              }
            ]
          })(
            <Input
              size="default"
              name="username"
              prefix={
                <Icon
                  type="user"
                  style={{
                    fontSize: 13
                  }}
                />
              }
              placeholder="用户名"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: '密码'
              }
            ]
          })(
            <Input
              size="default"
              name="password"
              prefix={
                <Icon
                  type="lock"
                  style={{
                    fontSize: 13
                  }}
                />
              }
              type="password"
              placeholder="密码"
            />
          )}
        </FormItem>
        <FormItem>
          <Button
            disabled={loginBtnDisable}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {loginBtnText}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const LoginForm = Form.create()(Login);
export default connect(({ login }) => ({
  login
}))(LoginForm);
