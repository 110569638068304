export default {
  namespace: 'home',
  state: {
    // username: '',
    // token: '',
    // stationId: '1',
    // stationName: ''
  },
  reducers: {
    loginSuccess(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    }
  }
};
