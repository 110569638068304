import React from 'react'
import E from 'wangeditor'
import { connect } from 'dva'
import { Input, Button, Modal, message, Select} from 'antd'
import './styles/index.less'
import {ctx} from '../../common/global'
import axios from 'axios'
const { Option } = Select;
let imgurlOne = null
let imgurlTwo = null
const token = localStorage.getItem('token')
let mianData = []
let newsId = ''
let imgStatus = false
class CaseAdmin extends React.Component {
  constructor () {
    super()
    this.state = {
      fileList: [],
      titleValue:'', 
      type:'',
      contentValus:'',
      typeStatus:false,
      titleStatus:false
    }
  }
  componentWillMount(){
    let id = this.props.match.params.id
    newsId = id
    this.props.dispatch({
      type:'detiles/getCaseContent',
      payload:{
        id:id
      }
    })
  }
  createE(a){
    const editor = new E(this.editor)
    // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
    // editor.customConfig.uploadImgServer = ctx+'/images/upload'
    editor.customConfig.showLinkImg = false
    editor.customConfig.pasteFilterStyle = false
    editor.customConfig.customUploadImg = function (files, insert) {
      var fd = new FormData();
      fd.append("imageFile", files[0]);
      axios.post(`${ctx}images/upload`,fd).then(rst=>{
        insert(rst.data.data);
        imgurlTwo = rst.data.data
        imgStatus = true
      }).catch(err=>{
        message.error(`error：${err}`)
      })
  }
   
    editor.customConfig.uploadFileName = 'image'
    editor.customConfig.zIndex = 100
    editor.customConfig.onchange = html => {
      this.setState({contentValus:html})
    }
    editor.create()
    editor.cmd.do('insertHTML',a)
  }
  componentDidMount () {
    let id = this.props.match.params.id
    axios.get(`${ctx}getCaseContent`,{params:{id:id}}).then(rst=>{
      this.createE(rst.data.data.content)
      mianData = rst.data.data
      
    }).catch(err=>{
      message.error(`error：${err}`)
    })
  }
  titleChange = (e,type) => {
    this.setState({[type]: e.target.value ,titleStatus:true})
  }
  handleChange(v){
    this.setState({type:v,typeStatus:true})
  }
  
  buttonSubmit = () => {
    let img = ''
    let tit = ''

    if(imgStatus){
      img = imgurlTwo
    }else{
      img = imgurlOne
    }

    if(this.state.titleStatus){
      tit = this.state.titleValue
    }else{
      tit = mianData.title
    }
    const {contentValus} = this.state    
    if(tit==='' || contentValus === '' ){
      message.error('请确保所有参数均已填写')
    }else if(img === null){
      message.error('请至少上传一张图片')
    }
    else{
      let data = {
        id:newsId,
        title:tit,
        imgURL:img,
        content:contentValus
      }
      axios({
        method:'POST',
        url:`${ctx}updateCase`,
        data:data,
        headers:{
          // "Content-Type":"application/json",
          Authorization:token,
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*' 
        }
      })
      .then(rst=>{
        
        const dt = rst.data
        console.log(rst)
        if(dt.status){
          Modal.success({
            title: '通知',
            content: dt.msg,
            onOk(){window.location.href = '/admin'}
          });
        }else{
          Modal.error({
            title: '通知',
            content: dt.msg
          });
          localStorage.clear()
          window.location.reload()
        }
        
        
      }).catch(err=>{
        message.error(`error：${err}`)
      })
    } 
  }
  render () {
    const {titleValue, fileList} = this.state
    const {casedata} = this.props.detiles
    if(casedata){
      imgurlOne = casedata.imgURL
    }
    const props = {
      fileList,
      action:'',
      onRemove: (file) => {
        this.setState(({ fileList }) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          }
        })
      },
      beforeUpload: (file) => {
        this.setState(({ fileList }) => ({
          fileList: [...fileList, file],
        }))
        console.log("图片",this.state.fileList)
        return false
      },
    }
    return (
      <div className='news-publish'>
        <div className="tips">请在内容中至少添加一张图片！</div>
        <div className='row row-title'>
            <span className="title">标题:</span>
            { casedata &&  (<Input defaultValue={casedata.title} onChange={(e) => {this.titleChange(e,'titleValue')}}  style={{width:500}} />)}
        </div>
        {/* <div className='row'>
            <span className="title">新闻类型:</span>
            { newsContent && ( <Select style={{ width: 120 }} defaultValue={newsContent.type} onChange={this.handleChange.bind(this)}>
            <Option value="product">产品动态</Option>
            <Option value="company">公司动态</Option>
            <Option value="industry" >行业资讯</Option>
            </Select>)}
          
        </div> */}
        <div className='row'>
          <span className="title" >内容:</span>
          <div className='label-content' ref={ref => { this.editor = ref }} />
        </div>
        <div className='row'>
          <div style={{marginTop: 20}}>
            <Button type='primary' className='row-submit' onClick={this.buttonSubmit}>确认提交</Button>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(({ detiles }) => ({
  detiles
}))(CaseAdmin); 