import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import history from './common/history';
import Content from './app.layout';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <Switch>
            <Content />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
