import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import history from './common/history';
import { Layout, Menu } from 'antd';
import nav from './common/nav';
import HeaderInfo from './components/HeaderInfo/HeaderInfo';
import { contentHeight } from './common/global';
import Login from './pages/Login/index';
import './common/global.less';
const { SubMenu } = Menu;
const { Content, Sider } = Layout;

export default class AppLayout extends Component {
  getRoute = nav => {
    return nav.map(ele => {
      if (ele.child) {
        return this.getRoute(ele.child);
      }
      if (ele.component) {
        return <Route key={ele.route} exact path={ele.route} component={ele.component} />;
      }
    });
  };
  getChild = child => {
    let dom = child.map((ele, i) => {
      return (
        <Menu.Item key={ele.route}>
          <Link to={ele.route}>{ele.title}</Link>
        </Menu.Item>
      );
    });
    return dom;
  };
  getSider = nav => {
    return nav.map(v => {
      if (v.route == '/login' || v.route == '/caseAdmin/:id' || v.route == '/newsAdmin/:id' ) return;
      if (v.component) {
        return (
          <Menu.Item key={v.route}>
            <Link to={v.route}>{v.title}</Link>
          </Menu.Item>
        );
      }
      if (v.child) {
        return (
          <SubMenu key={v.route} title={v.title}>
            {this.getChild(v.child)}
          </SubMenu>
        );
      }
    });
  };
  AuthComponent = pathname => {
      return this.MainComponent(pathname);
  };
  MainComponent = pathname => {
    return (
      <div className="layout">
        <HeaderInfo pathname={pathname} />
        <Layout>
          <Sider width={200} style={{ background: '#fff' }}>
            <Menu mode="inline" style={{ height: '100%', borderRight: 0 }}>
              {this.getSider(nav)}
            </Menu>
          </Sider>
          <Layout style={{ padding: 30 }}>
            <Content
              style={{
                margin: 0,
                minHeight: contentHeight
              }}
            >
              {this.getRoute(nav)}
            </Content>
            <div
              style={{
                textAlign: 'center',
                color: '#7b7b7b',
                padding: '25px 50px',
                width: '100%'
              }}
            >
              <div>
                <a href="https://www.wh-eco.com/contact" target="_blank">
                  联系我们
                </a>
                <span> | </span>
                <a href="https://www.wh-eco.com/about" target="_blank">
                  关于我们
                </a>
              </div>
              <span> ©2017 - 2019 易科捷（ 武汉） 生态科技有限公司 </span>
            </div>
          </Layout>
        </Layout>
      </div>
    );
  };
  render() {
    const { pathname } = history.location;
    const token = localStorage.getItem('token');
    if (token && token != 'null') {
      return this.AuthComponent(pathname);
    } else {
      return <Login />;
    }
  }
}
